html{
  font-family:  'Roboto', 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiesed;
  -moz-font-smoothing: antialiesed;
  -moz-osx-font-smoothing: antialiesed;
  position: relative;
  overflow-y: scroll;
}
body {
  display: flex;
  flex-direction: column;
  margin: 0px auto;
  padding: 0px;
  color: #253238;
  background: rgb(246, 246, 246);
  overflow: hidden;
}
a{
  text-decoration: none;
  color: inherit;
}
#name{
  text-align: center;
}
.clearfix {
  content: "";
  clear: both;
  display: table;
}
.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.3);
  z-index: 20;
  align-items: center;
  justify-content: center;
}
.popup-container {
  position: relative;
  width: 90%;
  height: 90%;
  overflow: auto;
  float: left;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 2.5%;
  background: white;
  box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
}

.popup-inner {
  position: relative;
  min-height: 100%;
  height: auto;
  overflow-x: hidden;
}
.popup-full-height {
  position: absolute;
  top: 0;
  left: 0;
  right: 128px;
  bottom: 0;
  min-height: 100%;
}
.popup-button:hover {
  background-color: #2283346c !important;
}
.popup-button {
  transition: 300ms all;
  background-color: #228333;
}
.popup-button-red:hover {
  background-color: rgba(255, 0, 0, 0.534) !important;
}
.popup-button-red {
  transition: 300ms all;
  background-color: rgba(255, 56, 56, 0.808);
}
.DayPicker-wrapper:focus {
  outline: none !important;
}
.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #f4433605 !important;
}
.DayPicker-Day--outside{
  color: rgba(139, 152, 152, 0.37);
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside){
  background-color: rgb(0 0 0 / 1%) !important;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--today) {
  color: #f44336a3 !important;
}
.DayPicker-Day--today:not(.DayPicker-Day--outside) {
  font-weight: bold;
  color: red !important
}
.DayPicker-Day--today:not(.DayPicker-Day--outside):not(.DayPicker-Day--selected){
  font-weight: bold;
  color: black !important
}
.foto-carnet-wrapper{
  position: absolute;
  top: 66px;
  right: 20px;
  width: 150px;
  height: 220px;
  z-index: 30; 
  font-size: 0.8em;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.MuiTableCell-root{
  font-size: 0.8rem !important;
}
.swal-text{
  text-align: justify;
}
.spacer{
  flex: 1 1 0 !important;
}
.title{
  overflow: visible !important;
}
.searchField{
  overflow: hidden !important;
  min-width: unset !important;
}
@media screen and (max-width: 588px){
  .spacer{
    display: none;
  }
  .root-Table{
    padding: 1rem 1rem !important;
    flex-direction: column !important;
  }
}
@media screen and (max-width: 1200px){
  .foto-carnet-wrapper{
    top: 116px;
    width: 114px;
    height: 172px;
  }
}
@media screen and (max-width: 900px){
  .foto-carnet-wrapper{
    width: 75px;
    height: 120px;
  }
  #logo-ayun{
    margin: 0 auto;
  }
  #logo-ayun-m{
    margin: 0 auto;
  }
}
@media screen and (max-width: 600px){
  .foto-carnet-wrapper{
    display: none;
  }
}